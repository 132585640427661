import { createError, defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { groupHistoryTrackingApi } from '@/apis/group.api';
import { PAGE_NOT_FOUND_404 } from '@/constants/error.const';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import type { SimpleGroupResponse } from '@/types/my-info/my-info-response.type';

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  const userStore = await useUserStore();
  const { joinedGroups } = storeToRefs(userStore);
  const { setGroupAddedToHistoryOrNot, isGroupAlreadyAddedToHistory } = useAppStore();
  const { groupId } = to.params;
  const isGroupValid = joinedGroups.value?.find(
    (item: SimpleGroupResponse) => item.groupId === groupId
  );
  if (!isGroupValid) {
    throw createError({
      statusCode: PAGE_NOT_FOUND_404,
      message: 'Page not found'
    });
  }
  if (groupId && typeof groupId === 'string' && !isGroupAlreadyAddedToHistory) {
    const isSuccessful = await groupHistoryTrackingApi([groupId as string]);
    if (isSuccessful) {
      setGroupAddedToHistoryOrNot(true);
    }
  }
});
